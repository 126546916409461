.auction-heading {
  margin: 1rem 0;
}
.auction-clock-icon {
  margin-right: 0.4rem;
  margin-left: 20px;
}
.auction-item {
  margin-bottom: 1rem;
}

.auction-item-card {
  border-radius: 12px;
}

.auction-item-card .ant-card-body {
  padding: 0px;
}

.auction-time-row .item {
  background-color: #f5f6f9;
  padding: 0.1rem 0.7rem;
  margin-left: 0.3rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.auction-time-row .item p {
  padding: 0;
  margin: 0;
  font-size: calc(1em - 0.1vw);
}
.auction-time-row .item img {
  max-height: 90%;
}
.bid-details-row {
  background-color: #f5f6f9;
  border-radius: 12px;
  padding: 1rem;
}
.auction-desc {
  /* font-size: medium; */
}

.auction-title-mobile {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  max-width: 100vw;
}

.auction-title-mobile:hover {
  text-decoration: underline;
}

.highestBidder {
  border-radius: 12px 12px 0px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  background-color: #e1ffe1;
  padding: 9px 16px;
}

.highestBidder p {
  color: black;
  font-weight: 14px;
  font-weight: 600;
  margin: 0;
}

.auctionPropertiesRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 5px 0px;
}

.firstRowContainer .auctionPropertiesRow span {
  min-width: 90px;
  margin: 0 10px;
}

.bidPriceCol {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px;
}

.bidPriceCol .favIconContainer {
  position: absolute;
  border: 1px solid #c9cbd5;
  border-radius: 4px;
  background-color: #f5f6f9;
  right: 0;
  top: 0;
  width: 36px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.bidPriceCol .auctionPropertiesRow {
  justify-content: space-between;
}

.bidPriceCol .auctionPropertiesRow span {
  text-align: left !important;
}

.bidNowBtnCol {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: self-end;
}

.bidNowButton {
  border-radius: 12px 0px 12px 0px;
  box-shadow: none;
  border: 0px;
  z-index: 2;
  color: white;
  font-size: 18px;
  font-weight: 900;
  width: 310px;
  height: 60px;
}

@media screen and (max-width: 575px) {
  .bidNowButton {
    border-radius: 0px 0px 12px 12px;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .auction-desc,
  .bid-details-row,
  .auction-details-btn {
    display: none;
  }
  .auction-time-row .item {
    /* background-color: transparent; */
    margin: 0.2rem 0;
    padding: 0;
  }
  .auction-time-row .item p {
    font-size: calc(1em - 0.2vw);
  }
  .auction-time-row .item img {
    margin-left: 0.1rem;
  }
  .auction-time-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .auction-price-mobile {
    margin: 0;
    padding: 0;
  }

  .auctionPropertiesRow {
    flex-direction: column;
  }

  .bidPriceCol {
    display: block !important;
  }

  .auctionPropertiesRow .textRight {
    text-align: left !important;
  }

  .bidNowButton {
    border-radius: 0px 0px 12px 0px;
    width: 100%;
  }
}

.auction-head-container {
  display: flex;
  justify-content: space-between;
}

.auction-head-container div:nth-child(1) h1 {
  line-height: 0px;
  font-size: 18px;
  font-weight: bolder;
}

.auction-head-container div:nth-child(1) p {
  margin-top: 17px;
}

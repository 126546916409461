.home-main {
  min-height: 100vh;
}
.header-section {
  background-color: #f5f6f9;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.categories-main {
  max-width: 100%;
  /* overflow: hidden; */
  overflow-x: auto;
  margin-top: 20px;
  /* padding-top: 15px; */
  position: relative;
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  overflow: -moz-scrollbars-none; /* Firefox */
  overflow-x: auto;
  overflow-y: hidden;
}

.disable-scrollbars::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.all-auctions-category {
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: none !important;
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.all-auctions-category img {
  width: 4rem;
  object-fit: cover;
}

.all-auctions-category p {
  font-size: 14px !important;
}

.call-auctions-category:hover {
  box-shadow: none !important;
}

.all-auctions-category .category-card {
  height: 0;
  transition: 0 !important;
  box-shadow: none !important;
  justify-content: center;
  height: 100px !important;
}

.category-item {
  margin-right: 1rem;
  cursor: pointer;
}
.category-item .category-card {
  height: 140px;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

.category-item .category-card .ant-card-body {
  padding: 0px;
}

.category-item .selected-category {
  border: 1px solid #c9cbd5;
}
.category-card img {
  width: 4rem;
  /* aspect-ratio: 1; */
  object-fit: cover;
}
.body-section {
  background-color: #f5f6f9 !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

@media screen and (min-width: 769px) {
  .protected-header-mobile {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .category-item .category-card {
    /* width: 8rem; */
    width: 75px;
    height: 65px;
  }
  .category-card img {
    width: 2rem;
  }

  .categories-main {
  }
}

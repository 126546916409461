.pageContainer {
  width: 100%;
  padding: 40px 0px;

  .fluidContainer {
    width: 80%;
    margin: auto;
    position: relative;

    h1 {
      margin: 20px 0px;
      font-weight: bolder;
      font-size: 23px;
      font-weight: bolder;
    }

    .arrowIcon {
      position: absolute;
      position: absolute;
      left: -20px;
      width: 60px;
      height: 40px;
      top: 18px;
    }

    .fluidRow {
      min-height: 50vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      p {
        font-size: 16px;
      }

      .leftRow {
        flex-flow: row;
        align-items: flex-start;
        border: 1px solid #c9cbd5;
        border-radius: 12px;
        padding: 20px;
        gap: 10px;
        p {
          margin: 0;
        }
      }

      .rightRow {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid #c9cbd5;
        border-radius: 12px;
        padding: 20px;
        gap: 15px;

        
        .termsAndCondition {
          cursor: pointer;
            text-decoration: underline;
        }

        @media screen and (max-width: 768px) {
          margin-top: 25px;
        }
      }
    }
  }
}

.headerContainer {
  width: 80%;
  margin: auto;
}

.login-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
    background-color: #FCFDFF;
    min-height: 100vh;
}
.login-main p {
    padding: 0;
}
.login-main img {
    /* width: 20%; */
    margin: 3rem 0;
}
.login-main .welcome-text * {
    text-align: center;
    padding: 0;
    margin: 0.5rem;
}
.login-main .input {
    width: 100%;
    /* margin-top: 0.8rem; */
}
.login-main .ant-form-item {
    width: 30vw;
}
.login-main .forgot-pwd-btn {
    width: 30vw;
    cursor: pointer;
}
.login-main button {
    /* margin-top: 0.6rem; */
}
.login-main .create-account-btn {
    cursor: pointer;
    margin-left: 5px;
}
.login-main .sign-in-btn {
    /* margin-bottom: 1rem; */
}
.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media screen and (max-width: 768px) {
    .login-main .ant-form-item {
        width: 80vw;
    }
    .login-main img {
        /* width: 30%; */
        margin: 3rem 0;
    }
    .login-main .forgot-pwd-btn {
        width: 80vw;
    }
}
.my-bids-main p,
h4,
h2 {
  padding: 0;
  margin: 0;
}
.my-bid-details {
  background-color: #f5f6f9;
  padding: 0.5rem 0.8rem;
  border-radius: 0.3rem;
  /* margin-top: 0.5rem; */
}
.my-bid-payment-date {
  background-color: #f5f6f9;
}
.my-bids-cell {
  background-color: #f5f6f9;
  border-radius: 0.4rem;
  padding: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.my-bids-cell p {
  font-size: smaller;
}
.my-bids-cell-won {
  padding: 0.5rem;
  border-radius: 0.4rem;
  background-color: #e1ffe1;
}
.t-status-won {
  color: #28c828;
  font-size: smaller;
}
.my-bid-footer-button {
  margin-top: 1rem;
  justify-content: flex-end;
}
.my-bid-footer-button .my-bid-payment-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  background-color: #fff !important;
}

.active-heading {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.bid-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.auction-heading {
  margin: 1rem 0;
}

.ant-card-bordered {
  position: relative;
  margin: 20px 0px;
  border: 1px solid lightgray;
}

.firstRowContainer {
  padding: 0px !important;
}

.firstRowContainer .auctionPropertiesRow span {
  min-width: 90px;
  text-align: left;
}

.firstRowContainer .auctionPropertiesRow .textRight {
  text-align: right;
}

.bidPriceCol {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
}

.bidStatusContainer {
  position: absolute;
  right: 0;
  top: 0;
}

.bidStatusContainer.ar {
  left: 0;
  right: auto; /* Reset the right property */
}

.bidPriceCol .auctionPropertiesRow {
  justify-content: space-between;
}

.bidPriceCol .auctionPropertiesRow span {
  text-align: left;
}

.auction-clock-icon-my-bids {
  margin-right: 10px;
}

@media screen and (max-width: 575px) {
  .bidNowButton {
    border-radius: 0px 0px 12px 12px;
    width: 100%;
  }

  .my-bid-footer-button {
    gap: 10px;
  }

  .my-bid-footer-button .shipmentBtn {
    width: 100%;
    position: absolute;
    right: 0;
    font-size: 14px;
    border-radius: 0px 0px 12px 12px;
  }
}

@media screen and (max-width: 768px) {
  .auctionPropertiesRow {
    flex-direction: column;
  }

  .bidPriceCol {
    display: block !important;
  }

  .bidPriceCol div {
    width: 100%;
  }

  .auctionPropertiesRow .textRight {
    text-align: left !important;
  }

  .bidNowButton {
    border-radius: 0px 0px 12px 0px;
    width: 100%;
  }
}

.filters-list {
  max-height: 5500px;
  overflow-y: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

/* For Chrome, Safari, and Opera */
.filters-list::-webkit-scrollbar {
  display: none;
}

.auction-head-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.auction-head-container div:nth-child(1) h1 {
  line-height: 0px;
  font-size: 18px;
  font-weight: bolder;
  margin-top: 10px;
}

.auction-head-container div:nth-child(2) p {
  padding: 0.5rem;
  border-radius: 0.4rem;
  margin-top: 0px !important;
}

.disclaimer-container {
  margin-top: 25px !important;
}

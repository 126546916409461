.common-image-input {
    border: 1.5px dashed #C9CBD5;
    /* padding: 0 1rem; */
    background-color: #ffff;
    padding: 0.5rem 0.8rem;
    /* height: 2.5rem; */
    border-radius: 12px;
    margin-bottom: 1rem;
  }
  

.footer {
    padding: 2rem var(--horizontal-padding);
    background-color: #FCFDFF;
}
.footer .third-row {
    background-color: #F5F6F9;
    padding: 1rem;
}
.footer .third-row a {
    color: #808185;
}
.footer .social-row img{
    max-height: 1.5rem;
    margin-right: 10px;
    position: cursor;
}

.ant-typography {
    color: black !important;
}

@media screen and (max-width: 768px) {
    .footer {
    padding: 2rem var(--horizontal-padding-mobile);
    }
    .footer .third-row {
        background-color: #F5F6F9;
        padding: 1rem;
        margin-top: 1.5rem;
    }
}
.pageContainer {
  width: 100%;
  padding: 40px 0px;

  .fluidContainer {
    width: 80%;
    margin: auto;
    position: relative;

    h1 {
      margin: 20px 0px;
      font-weight: bolder;
      font-size: 23px;
      font-weight: bolder;
    }

    .arrowIcon {
      position: absolute;
      position: absolute;
      left: -20px;
      width: 60px;
      height: 40px;
      top: 18px;
    }

    .fluidRow {
      min-height: 20vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      p {
        font-size: 16px;
        color: #9fa2ab;
      }

      h4 {
        font-size: 20px;
        color: #e51837;
      }

      .leftColContainer {
        border: 1px solid #c9cbd5;
        border-radius: 12px;
        padding: 40px 20px;

        p {
          margin-top: 5px;
        }

        .btnContainer {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
      }

      .rightColContainer {
        padding: 20px 0px;

        .rightMainRow {
          gap: 20px;
          .rightInfoRow {
            flex-flow: row;
            align-items: flex-start;
            border: 1px solid #c9cbd5;
            border-radius: 12px;
            padding: 20px;
            gap: 10px;
            p {
              margin: 0;
              color: black;
            }
          }

          .rightColContainer {
            border: 1px solid #c9cbd5;
            border-radius: 12px;
            padding: 20px;

            h4 {
              padding: 20px 0px;
              font-weight: bold;
            }

            p {
              margin: 5px 0px;

              b {
                color: #e51837;
                font-weight: 500;
              }

              u {
                padding: 0px 20px;
                color: #e51837;
              }
              
            }
          }
        }
      }
    }
  }
}

.headerContainer {
  width: 80%;
  margin: auto;
}

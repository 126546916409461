.loader-main {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffff;
    z-index: 999;
    opacity: 0.3;
    backdrop-filter: blur(10px);
}
.loader-main .spinner {
    z-index: 1000;
    
}
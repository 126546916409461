.header { 
    display: flex;
    flex-direction: row;
    background-color: #F1F2F5;
    justify-content: space-between;
    padding: 0 var(--horizontal-padding);
}
.header-text {
    font-size: 14px;
    color: #9FA2AB;
}
.header .first-row * {
    margin-left: 1rem;
}
.header .first-row img {
    max-height: 1.5rem;
    margin: 0px;
    margin-top: 5px;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .header {
        /* display: none; */
        flex-direction: column;
        padding: 0 var(--horizontal-padding-mobile);
    }
    .header .first-row * {
        margin-left: 0;
        margin-right: 10px;
        row-gap: 1rem;
    }
}
.mb-notifications-main {
  /* background-color: #ffff; */
    /* padding-top: 1rem; */
}
.mb-notifications-main .notifications-main{
    background-color: #f5f6f9;

}
.notifications-main  .mb-notifications-main {
    border: none;
}
.mb-notif-header {
    margin-top: 2rem;
}
.mb-notif-header button {
    outline: none;
    background-color: transparent;
    border: none;
}
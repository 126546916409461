@import "../../../assets/styles/colors.scss";

.primary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $primary-btn-bg-color;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  font-weight: bold;
  padding: 20px 55px;

  &:hover {
    background-color: #ffe6e7;
  }
  

}

.outlined {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #E51837;
  border: 1px solid #E51837;
  border-radius: 6px;
  font-size: 18px;
  font-weight: bold;
  padding: 19px 55px;
}
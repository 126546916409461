.common-layout section {
  padding: 0 var(--horizontal-padding);
  background-color: #f5f6f9;
}

@media screen and (max-width: 767px) {
  .common-layout section {
    padding: 0 var(--horizontal-padding-mobile);
  }
}

.min-container {
  min-height: 50vh !important;
  /* background-color: rebeccapurple; */
}

.auction-detail-main {
  padding: 3rem;
}

.auction-detail-main h3 {
  padding: 0;
  margin: 0;
}

.auction-detail-title {
  max-width: 80%;
}

.auction-detail-main .auction-cover {
  border-radius: 0.3rem;
  aspect-ratio: 1;
  height: 100%;
  object-fit: cover;
}

.auction-detail-main div h1:nth-child(1) {
  line-height: 0px;
  font-size: 18px;
  font-weight: bolder;
}

.auction-detail-main .auction-image-viewer {
  position: absolute;
  bottom: 0.5rem;
  left: 1.5rem;
  background-color: #808185;
  border-radius: 0.3rem;
  outline: none;
  border: none;
  padding: 0.6rem 0.8rem;
  color: #ffff;
  font-family: Manrope-Medium;
  cursor: pointer;
}

.auction-detail-main .caret {
  background-color: #808185;
  width: 0.1rem;
  height: 80%;
}

.auction-detail-main .auction-details p,
h7 {
  padding: 0;
}

.auction-detail-main .auction-details {
  background-color: #ffff;
  border-radius: 0.4rem;
  /* padding: 42px 46px; */
  padding-left: 46px;
  padding-top: 20px;
  padding-right: 42px;
  padding-bottom: 12px;
  border: 0.5px solid #c9cbd5;
  margin-bottom: 1rem;
}

.auction-detail-main .auction-details .bid-amount-label {
  font-size: calc(1em - 0.1vw);
}

.auction-detail-main .auction-detail-label {
  font-family: Manrope-Medium;
  color: #9fa2ab;
  font-size: calc(1em - 0.1vw);
  padding: 0;
  margin-bottom: 0.3rem;
}

.last-bid-price-div {
  background-color: #f5f6f9;
  border-radius: 0.5rem;
  width: 295px;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.watch-list-btn {
  background-color: #f5f6f9;
  border: 1px solid #c9cbd5;
  border-radius: 0.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0 1rem;
}

.bidNowButtonAtAuctionDetails {
  width: 100%;
  margin-top: 20px;
}

.auction-details-first-col {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 42px 0px 46px 46px; */
  padding-left: 46px;
  padding-top: 28px;
  padding-right: 42px;
  padding-bottom: 0px;
  background-color: #ffff;
  border-radius: 0.4rem;
  border: 0.5px solid #c9cbd5;
  margin-bottom: 1rem;
}

.auctionPropertiesRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px !important;
}

.firstRowContainer .auctionPropertiesRow span {
  min-width: 90px;
}

.firstRowContainer .auctionPropertiesRow .textRight {
  text-align: right;
}

.firstRowContainer .auctionPropertiesRow span {
  min-width: 90px;
}

.firstRowContainer .auctionPropertiesRow .textRight {
  text-align: right;
}

.bidPriceCol .auctionPropertiesRow {
  justify-content: space-between;
}

.bidPriceCol .auctionPropertiesRow span {
  text-align: left;
}

.footerButtons {
  margin: 50px;
}

.auction-clock-icon-auction-details {
  margin-left: 0px;
  margin-right: 10px;
}

.bidAmountPriceRow {
  margin-bottom: 10px;
}

.chatNowBtn {
  margin-right: 10px;
}

.termsAndConditionsBtn {
  margin-left: 10px;
}

@media screen and (max-width: 768px) {
  .auction-detail-main {
    padding: 3rem 0;
  }

  .auction-detail-title {
    max-width: 70%;
  }

  .auction-detail-main .auction-details {
    justify-content: space-between;
  }

  .last-bid-price-div {
    padding: 0.2rem 0;
    width: 100%;
  }

  .chatNowBtn {
    margin: 0px 0px 15px 0px;
  }

  .termsAndConditionsBtn {
    margin: 0px;
  }
}

@media screen and (max-width: 1320px) {
  .auctionPropertiesRow {
    flex-direction: column;
  }

  .bidPriceCol {
    display: block !important;
  }

  .auctionPropertiesRow .textRight {
    text-align: left !important;
  }

  .last-bid-price-div {
    margin-bottom: 20px;
    width: 100%;
  }
}

.custom-col {
  margin-top: -10px !important;
}

.bid-proxy {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.red-asterisk {
  color: #e51837;
}

.disclamer {
  font-size: 14px;
  text-align: left;
  font: normal normal medium 14px/19px Manrope;
  letter-spacing: 0px;
  opacity: 1;
}

.custom-tooltip {
  background: #ffff !important;
  opacity: 1 !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: black;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid c9cbd5;
}

.pageContainer {
  width: 100%;
  padding: 40px 0px;

  .fluidContainer {
    width: 80%;
    margin: auto;
    position: relative;

    h1 {
      margin: 20px 0px;
      font-size: 23px;
      font-weight: bold;
    }

    .arrowIcon {
      position: absolute;
      position: absolute;
      left: -20px;
      width: 60px;
      height: 40px;
      top: 18px;
    }

    .fluidRow {
      .leftCol {
        min-height: 50vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .leftContainer {
          p {
            font-size: 15px;
            text-align: justify;
          }
          
          
        }
      }

      .rightCol {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 80%;
        }
      }

      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse !important;
        gap: 20px;

        img {
          margin: 50px;
        }
      }

      @media screen and (max-width: 425px) {
        img {
          margin: 0px;
        }
      }
    }
  }
}

.headerContainer {
  width: 80%;
  margin: auto;
}
.common-input {
    padding: 0.5rem 0.8rem;
    border-color: #C9CBD5;
    font-family: Manrope-Regular;
    color: #808185;
    height: 2.5rem;
    border-radius: 12px;
} 
.ant-input-suffix {
    max-height: 2rem;
}
.date-picker  {
    width: 100%;
}
.react-tel-input .invalid-number-message {
    position: absolute;
    z-index: 1;
    font-size: 13px;
    left: 0 !important;
    bottom: -20px !important;
    top: unset !important;
    background: transparent !important;
    padding: 0 2px;
    color: #de0000;

}
.phone-input-ar .react-tel-input .selected-flag .arrow {
    left: unset !important;
    right: 20px !important
}

.flag-dropdown {
    border-right: 0px !important;
    background-color: transparent !important;
}
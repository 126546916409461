.m-drawer {

}
.account-status-header {
    background-color: #F5F6F9;
    border: 1px solid #C9CBD5;
    padding: 0 1rem;
    border-radius: 0.5rem;
}
.t-drawer-header {
    font-size: 14px;
}
.m-drawer p {
    padding: 0;
}
.drawer-navlink-list {
    display: flex;
    flex-direction: column;
}
.drawer-navlink-list a {
    color: black;
    font-family: Manrope-Medium;
    font-size: 15px;
    margin-bottom: 0.3rem;
}
.drawer-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    background-color: #F5F6F9;
    padding: 1rem 0;
}
.forgot-password-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding-top: 1rem; */
    background-color: #FCFDFF;
}

.forgot-password-main form {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.forgot-password-main img {
    margin: 3rem 0;
}

.forgot-password-main button {
    align-self: flex-end;
}
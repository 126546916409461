
.deleteModal {
  width: 500px;
  .commonDeleteModalContainer {
    .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
      h4 {
        color: black;
        text-align: center;
        font-size: 24px;
      }
    }

    .para {
      p {
        color: #808185;
        margin-bottom: 30px;
      }
    }

    .btnsRow {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 20px;
    }
  }

  :global(.ant-modal-title) {
    color: red;
  }
}

:root{
  --horizontal-padding: 6rem;
  --horizontal-padding-mobile: 2rem;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* fonts */
@font-face {
  font-family: 'Manrope-Regular';
  src: url('fonts/Manrope-Regular.ttf');
}

@font-face {
  font-family: 'Manrope-Medium';
  src: url('fonts/Manrope-Medium.ttf');
}

@font-face {
  font-family: 'Manrope-Bold';
  src: url('fonts/Manrope-Bold.ttf');
}

@font-face {
  font-family: 'Manrope-SemiBold';
  src: url('fonts/Manrope-SemiBold.ttf');
}

@font-face {
  font-family: 'Manrope-ExtraBold';
  src: url('fonts/Manrope-ExtraBold.ttf');
}

@font-face {
  font-family: 'Manrope-Light';
  src: url('fonts/Manrope-Light.ttf');
}

.updatePasswordContainer {
  margin-top: 50px;
  padding-bottom: 50px;
  .pageContainer {
    width: 80%;
    padding: 40px 0px;
    margin: auto;
    border: 1px solid #c9cbd5;
    border-radius: 15px;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .fluidContainer {
      width: 80%;
      margin: auto;
      position: relative;

      h1 {
        margin: 20px 0px;
        font-weight: bolder;
        font-size: 23px;
        font-weight: bolder;
      }

      .arrowIcon {
        position: absolute;
        position: absolute;
        left: -20px;
        width: 60px;
        height: 40px;
        top: 18px;
      }

      .fluidRow {
        display: flex;
        flex-direction: row;
        margin-top: 40px;

        .file {
          width: 100%;
        }

        :global(.ant-space-compact) {
          width: 100%;
        }

        .btnContainer {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin: 40px 0px;
          .btn {
            padding: 15px 50px;
          }
        }
      }
    }
  }
}


.headerContainer {
  width: 80%;
  margin: auto;
}

.pageContainer {
  width: 100%;
  min-height: 60vh;
  padding: 40px 0px;
  display: flex;
  flex: content;
  justify-content: center;
  align-items: center;
  background-color: #F5F6F9;

  .fluidContainer {
    text-align: center;
    box-sizing: border-box;
    width: 434px;
    min-height: 200px;

    img {
      width: 115px;
      height: auto;
    }

    h1 {
      color: #E51837;
      font-size: 78px;
      margin: 20px;
      span {
        color: #E51837;
        font-size: 23px;
      }
      p {
        font-size: 16px;
      }
    }
  }
}

.headerContainer {
  width: 80%;
  margin: auto;
}
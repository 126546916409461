.pageContainer {
    width: 100%;
    padding: 40px 0px;
  
    .fluidContainer {
      width: 80%;
      margin: auto;
      border: 2x solid blue;
      position: relative;
  
      h1 {
        margin: 20px 0px;
        font-size: 23px;
        font-weight: bolder;
      }
  
      .arrowIcon {
        position: absolute;
        position: absolute;
        left: -20px;
        width: 60px;
        height: 40px;
        top: 18px;
      }
  
      .fluidRow {
        min-height: 50vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
  
        .leftContainer {
          p {
            font-size: 15px;
          }
          
          h4 {
            font-size: 22px;
            font-weight: bolder;
          }
  
          .listPara {
            margin-left: 40px;
          }
  
          h2 {
            margin: 20px 0px;
            font-weight: bolder;
            font-size: 18px;
            font-weight: bolder;
          }
        }
      }
    }
  }
  
  .headerContainer {
    width: 80%;
    margin: auto;
  }
  
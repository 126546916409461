.regular__font {
    font-family: 'Manrope-Regular';
  }
  
  .medium__font {
    font-family: 'Manrope-Medium';
  }
  
  .bold__font {
    font-family: 'Manrope-Bold';
  }
  
  .semiBold__font {
    font-family: 'Manrope-SemiBold';
  }
  
  .extraBold__font {
    font-family: 'Manrope-ExtraBold';
  }
  
  .light__font {
    font-family: 'Manrope-Light';
  }
  
  
  
  .Toastify__toast-body {
    margin: auto 0;
    flex: 1 1 auto;
    padding: 6px;
    display: flex;
    align-items: center;
    font-size: 13px !important;
    font-family: Manrope-Regular !important;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  body,
  caption {
    color: var(--primary);
    font-family: Manrope-Regular;
    font-weight: normal;
  }

  .t-red {
    color: #E51837
  }
  .t-black {
    color: #292D32
  }
  .t-white {
    color: #FFFFFF;
  }
  .t-slate {
    color: #808185;
  }
  .t-slate-light {
    color: #9FA2AB;
  }
  

  .t-underline {
    text-decoration: underline;
  }

  .t-cursor {
    cursor: pointer;
  }

  .t-center {
    text-align: center;
  }
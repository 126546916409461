.all-categories-main {
    min-height: 100vh;
}

.back-button {
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0;
}


.protected-header-desktop {
  background-color: #f5f6f9;
}
.protected-header-desktop header {
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.protected-header-desktop header a {
  color: #9fa2ab;
  margin: 0 0.9rem;
  font-family: Manrope-Regular;
}
.protected-header-desktop header .language-dropdown {
  margin: 0 0.9rem;
}

.my-account-dropdown {
  .account-dropdown-row {
    gap: 5px;
    margin: 0px 10px;
  }
}

.dropdown-render-container {
  border: 1px solid #c9cbd5;
  padding: 20px 0px;
  min-width: 275px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

  
  .account-status-header {
    margin: 10px 20px;
    padding: 0px 20px;
  }


  .menu-item-container {
    box-shadow: none;
    padding: 0 !important;

    .menu-title-heading-h2 {
      margin: 5px 15px;
      font-size: 14px;
      color: #808185;
    }

    .custom-main-menu {
      padding: 0 !important;
      box-shadow: none;
      margin: 10px 20px;
      color: black;
      font-size: 15px;

      .ant-dropdown-menu-item {
        padding: 0px 12px !important;
      }
    }
  }

  .dropdown-footer {
    background-color: #f5f6f9;
    padding: 10px 10px;
  }
  .dropdown-footer > p {
    color: #808185;
    font-size: 14px;
    text-align: center !important;
  }

  .row-btns-container {
    justify-content: space-between;
    gap: 20px;
  }

  .logout {
    padding: 0px 30px;
    cursor: pointer;
  }
}

.protected-header-desktop header a:hover {
  color: #292d32;
}
.protected-header-desktop .active {
  color: #292d32;
  font-family: Manrope-Bold;
}
.protected-header-desktop header .header-nav-row {
  display: flex;
  align-items: center;
}
.header-nav-row .caret {
  height: 1rem;
  width: 1px;
  background-color: #9fa2ab;
}

@media screen and (max-width: 768px) {
  .protected-header-desktop {
    display: none;
  }
  .protected-header-mobile {
    background-color: #f5f6f9;
    padding-top: 2rem !important;
  }
}

.signup-main {
  padding-top: 1rem;
  background-color: #fcfdff;
}
.signup-main .signup-form .ant-form-item {
  width: 25vw;
}
.image-input-width {
  width: 25vw;
}
.signup-main .ant-space-compact {
  width: 25vw;
}
.signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
}
.signup-form .whatsapp_caption {
  padding: 0;
  margin: 0;
  width: 25vw;
  font-size: 12px;
  margin-top: -19px;
  padding-bottom: 15px;
  color: #808185;
}
.signup-main p {
  padding: 0;
}
.signup-main .forgot-pwd-btn {
  width: 30%;
}
.signup-main button {
  margin-top: 0.6rem;
}
.signup-main .create-account-btn {
  cursor: pointer;
  margin-left: 5px;
}
.signup-main .sign-in-btn {
  margin-bottom: 1rem;
}

.pass-img {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  right: 20px;
  top: 10px;
}

.pass-img.ar {
  left: 20px;
  right: auto; /* Reset the right property */
}

.pass-staric {
  position: absolute;
  z-index: 1;
  margin-top: 12px;
  margin-left: 10px;
  padding-bottom: 0px;
}

.invisible-input {
  color: transparent;
}

.common-input {
  background-color: transparent;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .signup-main {
    padding: 2rem 0;
  }
  .signup-main .signup-form .ant-form-item {
    width: 80vw;
  }
  .image-input-width {
    width: 80vw;
  }
  .signup-main .ant-space-compact {
    width: 80vw;
  }
  .signup-form .whatsapp_caption {
    width: 80vw;
  }
}

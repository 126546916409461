.pageContainer {
  width: 100%;
  padding: 40px 0px;

  .fluidContainer {
    width: 80%;
    margin: auto;
    position: relative;

    h1 {
      margin: 20px 0px;
      font-weight: bolder;
      font-size: 23px;
      font-weight: bolder;
    }

    .arrowIcon {
      position: absolute;
      position: absolute;
      left: -20px;
      width: 60px;
      height: 40px;
      top: 18px;
    }

    .fluidRow {
      min-height: 30vh;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      p {
        font-size: 14px;
        color: #9fa2ab;
        font-family: Manrope;
      }

      h6 {
        font-size: 16px;
        font-weight: bold;
        margin: 0;
      }

      .leftRow {
        border: 1px solid #e51837;
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        padding: 25px;
        margin: 20px 0px;
        gap: 10px;

        p {
          margin: 0;
          font-family: Manrope-Regular;

        }

        .headingRow {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 10px 0px;

          .leftHeadingBtnRow {
            gap: 20px;

            h6 {
              cursor: pointer;
            }
          }
        }
      }

      .rightRow {
        border: 1px solid #c9cbd5;
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        padding: 25px;
        margin: 20px 0px;
        gap: 10px;
        cursor: pointer;        

        p {
          margin: 0;
        }

        .headingRow {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 10px 0px;

          .leftHeadingBtnRow {
            gap: 20px;
            h6 {
              cursor: pointer;
            }
          }
        }
      }

      .rightRowActive {
        border: 5px solid #c9cbd5;
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        padding: 25px;
        margin: 20px 0px;
        gap: 10px;
        cursor: pointer;

        p {
          margin: 0;
        }

        .headingRow {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 10px 0px;

          .leftHeadingBtnRow {
            gap: 20px;
            h6 {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.headerContainer {
  width: 80%;
  margin: auto;
}
